<template>
  <div class="h-full flex flex-col pb-0">
    <loader :loading="loading" :backdrop="true" />
    <div class="text-blue-500 mt-2">
      <button
        class="cursor-pointer border-b border-blue-500 font-bold"
        @click="isOpen = true"
      >
        add new quext concept
        <icon name="plus" class="h-4 w-4 inline text-blue-500 cursor-pointer" />
      </button>
    </div>
    <ListQuextConcept
      @click="handleRowClicked"
      :key="key"
    />
    <div class="z-50 bottom-0 left-0 fixed w-full bg-white pb-6" v-if="isOpen || is_on_edit_mode">
      <p class="bg-gray-300 py-2 px-8 font-bold">{{isOpen ? 'add new quext concept' : `editing quext concept: ${quext_concept_name_to_edit}`}}</p>
      <div class="w-1/4 p-8">
        <div class="form-row">
          <div class="form-col">
            <label for="quext_concept_name">Quext concept name</label>
            <input
              name="quext_concept_name"
              type="text"
              class="form-input"
              v-model="quext_concept_name"
            />
            <span v-if="hasError('quext_concept_name')" class="form-error">
              {{ showError("quext_concept_name") }}
            </span>
          </div>
        </div>
      </div>
      <div class="flex justify-between py-2 px-8">
        <div class="">
          <button @click="handleCancel">cancel</button>
        </div>
        <div>
          <button v-if="is_on_edit_mode" @click.stop="handleDelete" class="btn-danger mx-4 btn-solid">
            delete quext concept
          </button>
          <button v-if="is_on_edit_mode" @click.stop="updateQuextConcept" class="btn-primary btn-solid">
            update and close
          </button>
          <button v-else @click.stop="saveAndClose" class="btn-primary btn-solid">
            save and close
          </button>
        </div>
      </div>
    </div>
    <modal-footer v-if="!isOpen && !is_on_edit_mode" :footer="footer" :tertiary="cancel" />
  </div>
</template>

<script>
import AuthMixin from "@/components/auth/AuthMixin";
import ModalNavigation from "@/mixins/ModalNavigation";
import ModalFooter from "@/components/ui/modals/ModalFooter";
import Icon from "@/components/ui/Icon";
import Loader from "@/components/ui/Loader";
import NotifyMixin from "@/mixins/NotifyMixin";
import EventBus from "@/utils/EventBus";
import ListQuextConcept from "@/views/lease/PartnerDocuments/ListConcept.vue";

import { mapGetters } from "vuex";

export default {
  name: "ManageQuextFields",
  components: { Loader, ModalFooter, Icon, ListQuextConcept },
  mixins: [AuthMixin, ModalNavigation, NotifyMixin],
  data() {
    return {
      loading: false,
      partner: null,
      quextFields: [],
      toDelete: "",
      isOpen: false,
      quext_concept_name: "",
      quext_concept_name_to_edit: "",
      footer: {
        tertiaryButton: "cancel"
      },
      is_on_edit_mode: false,
      key: 0,
    };
  },
  computed: {
    ...mapGetters({
      getPartner: "leases/getPartner",
      getQuextFields: "leases/getQuextFields",
    }),
    data_types() {
      return [{
        key: 'string',
        value: 'string'
      },
      {
        key: 'numeric',
        value: 'numeric'
      },
      {
        key: 'date',
        value: 'date'
      },
      {
        key: 'boolean',
        value: 'boolean'
      }]
    }
  },
  watch: {
    getQuextFields: function () {
      this.quextFields = [];
      
    },
  },
  methods: {
    handleCancel() {
      this.isOpen = false;
      this.is_on_edit_mode = false;
      this.quext_concept_name = "";
    },
    handleRowClicked(row) {
      this.quext_concept_name = row.data.quext_concept_name;
      this.quext_concept_name_to_edit = row.data.quext_concept_name;
      this.is_on_edit_mode = true;
    },
    handleDelete() {
      EventBus.emit("show-modal-confirmation", {
        id: "lease-service-manage_quext_fields",
        title: "manage quext fields",
        showConfirmation: true,
        confirmationMessage: `Are you sure you want to delete this quext field: ${this.quext_concept_name}`,
        confirmationType: "warning",
      });
    },
    onDelete() {
      this.loading = true;
      this.$leasingDataProvider.delete('quext_concept', {
          quext_concept_name: this.quext_concept_name,
        })
        .then(() => {
          this.key += 1;
          this.handleCancel();
          this.notifySuccess("the quext concept was deleted successfully");
        })
        .catch((error) => this.notifyError('Something went wrong, please try again later.', error))
        .finally(() => this.loading = false);
    },
    async saveAndClose () {
      this.loading = true;
      await this.$leasingDataProvider.create('quext_concept', {
        data: {
          quext_concept_name: this.quext_concept_name
        }
      })
      .then((res) => {
        if (res.status === 201) {
          this.key += 1;
          this.handleCancel();
          this.notifySuccess("the quext concept was created successfully");
        }
      })
      .catch(() => this.notifyError('Something went wrong, please try again later.'))
      .finally(() => this.loading = false);
    },
    updateQuextConcept() {
      this.loading = true;
        this.$leasingDataProvider.patch('quext_concept', {
          quext_concept_name: this.quext_concept_name_to_edit,
          data: {
            new_quext_concept_name: this.quext_concept_name
          }
      })
      .then((res) => {
        if (res.status === 200) {
          this.key += 1;
          this.handleCancel();
          this.notifySuccess("the quext concept was updated successfully");
        }
      })
      .catch(() => this.notifyError('Something went wrong, please try again later.'))
      .finally(() => this.loading = false);
    },
    cancel() {
      this.$router.push({ name: "manage-partner-documents.index" });
    },
  },
  validations: {
    "quext_concept_name": "required | is_empty"
  },
  created() {
    EventBus.on("confirm-lease-service-manage_quext_fields", () => {
      this.onDelete();
    });
    EventBus.on(
      "cancel-confirmation-lease-service-manage_quext_fields",
      () => {
        this.toDelete = "";
      }
    );
    this.initValidator();
    this.validator.extend("is_empty", function (value) {
      return !value.match(/^\s+$/);
    });
    this.validator.setCustomErrorMessages({
      "quext_concept_name": "this field is empty."
    });
  },
};
</script>
<style scoped>
ul:before {
  content: attr(aria-label);
  font-size: 1rem;
  font-weight: bold;
  color: black;
}
ul > li {
  @apply py-3 font-light text-black;
}
.inputs-wrap {
  @apply pb-2;
  height: calc(72vh - var(--menuHeight));
  overflow-y: auto;
}
button {
  outline: none;
  box-shadow: none;
}
</style>
